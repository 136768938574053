import { Cube02Icon } from '@expo/styleguide-icons/outline/Cube02Icon';

import { TableCell } from '~/ui/components/Table/TableCell';
import { TableCellText } from '~/ui/components/Table/TableCellText';
import { TooltipContent } from '~/ui/components/Tooltip/TooltipContent';
import { TooltipRoot } from '~/ui/components/Tooltip/TooltipRoot';
import { TooltipTrigger } from '~/ui/components/Tooltip/TooltipTrigger';
import { A, FOOTNOTE } from '~/ui/components/text';

type Props = {
  runtimeVersion: string;
  channel: string;
  url: string;
};

export function DeploymentCell({ runtimeVersion, channel, url }: Props) {
  return (
    <A href={url} className="flex h-full items-center truncate">
      <TableCell
        hideOnMobile
        className="min-h-[52px] w-full items-center gap-2 self-stretch truncate"
        theme="interactive">
        <div className="flex flex-col items-start truncate">
          <TooltipRoot>
            <TooltipTrigger>
              <TableCellText className="truncate">{runtimeVersion}</TableCellText>
            </TooltipTrigger>
            <TooltipContent>
              <FOOTNOTE>Runtime version</FOOTNOTE>
            </TooltipContent>
          </TooltipRoot>

          <TooltipRoot>
            <TooltipTrigger className="flex w-fit items-center gap-1 truncate">
              <Cube02Icon className="icon-sm shrink-0 text-icon-tertiary" />
              <TableCellText className="truncate" theme="secondary">
                {channel}
              </TableCellText>
            </TooltipTrigger>
            <TooltipContent>
              <FOOTNOTE>Channel</FOOTNOTE>
            </TooltipContent>
          </TooltipRoot>
        </div>
      </TableCell>
    </A>
  );
}
